<template>
  <div>
    <cp-confirm-modal
      ref="cpConfirmModal"
      :content-class="'activate-token'"
      :footer-class="'activate-token'"
      :is-ok-disabled="isDeployButtonDisabled"
      :item="token"
      :ok-only="true"
      :ok-text="$t('manageToken.activateToken.modal.continue')"
      :size="'lg'"
      :title="$t('manageToken.activateToken.modal.tokenDeployment')"
      :title-style="'confirm-modal-title'"
      @onCancel="$emit('step-submitted', false)"
      @onOk="doTokenDeployment"
    >
      <div class="modal-body-text">
        <div class="d-flex align-self-md-start token-description-container">
          <div class="token-description-column">
            <div class="token-description-row">
              <span class="title-column">{{ $t('manageToken.activateToken.modal.tokenName') }}</span>
              <span>{{ deploymentToken.name }}</span>
            </div>
            <div class="token-description-row">
              <span class="title-column">{{ $t('manageToken.activateToken.modal.tokenTicker') }}</span>
              <span>{{ deploymentToken.symbol }}</span>
            </div>
            <div class="token-description-row">
              <span class="title-column">{{ $t('manageToken.activateToken.modal.tokenDecimals') }}</span>
              <span>{{ deploymentToken.decimals }}</span>
            </div>
            <div class="token-description-row">
              <span class="title-column">{{ $t('manageToken.activateToken.modal.tokenBlockchain') }}</span>
              <div class="blockchain-pill">
                <img
                  :src="getBlockchainIcon()"
                  alt="Blockchain Icon"
                  class="blockchain-icon"
                >
                <span>{{ getBlockchain() }}</span>
              </div>
            </div>
            <div
              v-if="!isAlgorandNetwork"
              class="token-description-row"
            >
              <span class="title-column">{{ $t('manageToken.activateToken.modal.complianceType') }}</span>
              <span>{{
                deploymentToken.complianceType[0].toUpperCase() + deploymentToken.complianceType.slice(1)
              }}</span>
            </div>
          </div>
          <div
            v-if="!this.isAlgorandNetwork"
            class="token-description-column"
          >
            <deployerWallerLite />
          </div>
        </div>

        <div class="d-flex flex-column align-self-center">
          <b-alert
            show
            variant="warning"
          >
            <div class="deployer-warning-lite">
              <img
                :src="this.warningIcon"
                class="warning-icon-lite"
              >
              <span class="align-self-center">
                {{ $t('manageToken.activateToken.modal.detailsNote') }}
              </span>
            </div>
          </b-alert>
        </div>
      </div>
    </cp-confirm-modal>
    <div
      v-if="this.processingActivate"
      class="loading-cover"
    >
      <b-spinner
        class="align-middle loading-spinner"
        huge
      />
      <div class="loading-text">
        <span>
          {{ $t('manageToken.activateToken.processingRequest1') }}
        </span>
        <span>
          {{ $t('manageToken.activateToken.processingRequest2') }}
        </span>
      </div>
    </div>
    <div class="my-5 d-flex flex-column align-items-center">
      <deployerWallet v-if="tokenIsDeployable && !this.isAlgorandNetwork" />
      <span
        v-else-if="this.isAlgorandNetwork"
        class="main-text justify-content-center"
      >
        {{
          this.tokenIsDeployable ? $t('manageToken.activateToken.introAlgorand') : deploymentToken.status.toUpperCase()
        }}
      </span>
      <activate-steps v-else />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CpConfirmModal from '~/components/common/modals-components/confirm-modal';
import CpStaticPathGenerationMixin from '~/mixins/static-path-generation';
import activateSteps from './activate-token-components/activate-steps.vue';
import deployerWallet from './deployer-wallet/deployer-wallet.vue';
import deployerWallerLite from './deployer-wallet/deployer-wallet-lite.vue';
import AvalancheLogo from '~/assets/img/blockchains/avalanche.svg';
import EthereumLogo from '~/assets/img/blockchains/ethereum.svg';
import GanacheLogo from '~/assets/img/blockchains/ganache.svg';
import PolygonLogo from '~/assets/img/blockchains/polygon.svg';
import AlgorandLogo from '~/assets/img/blockchains/algorand.svg';
import QuorumLogo from '~/assets/img/blockchains/quorum.svg';
import BesuLogo from '~/assets/img/blockchains/besu.svg';
import AptosLogo from '~/assets/img/blockchains/aptos.svg';
import SolanaLogo from '~/assets/img/blockchains/solana.svg';
import warningIcon from '~/assets/img/warning-icon.svg';


export default {
  components: {
    CpConfirmModal,
    activateSteps,
    deployerWallerLite,
    deployerWallet,
  },

  mixins: [
    CpStaticPathGenerationMixin,
  ],
  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gasPriceInGwei: '',
      estimatedDeploymentGas: '',
      deployTokenTitle: '',
      token: {},
      showWebsocketLogs: false,
      warningIcon,
    };
  },

  computed: {
    ...mapState('configToken', ['deploymentToken', 'deploymentAvailableNetworks', 'submitButtonDisabled', 'processingActivate']),
    ...mapState('issuersInfo', ['issuerId']),

    tokenIsDeployable() {
      return !this.deploymentToken.status || this.deploymentToken.status === 'initial';
    },
    tokenIsReDeployable() {
      return !this.deploymentToken.status || this.deploymentToken.status === 'failure';
    },
    showEthAmount() {
      const ethAmout = (this.gasPriceInGwei * this.estimatedDeploymentGas) / (10 ** 9);
      return ethAmout.toFixed(5);
    },
    isAlgorandNetwork() {
      return this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand');
    },
    isDeployButtonDisabled() {
      return this.submitButtonDisabled;
    },
  },

  watch: {
    submitClicked(value) {
      if (value) {
        this.displayDeployModal();
      }
    },
  },

  methods: {
    ...mapActions('configToken', ['deployToken', 'retryDeploy', 'getDeploymentGasCostEstimationInGwei', 'setProcessingActivate']),
    isEthereumPublicNetwork() {
      return this.deploymentToken.provider
          && (this.deploymentToken.provider.includes('ethereum') || this.deploymentToken.provider.includes('polygon'));
    },
    getBlockchain() {
      if (this.deploymentToken.provider) {
        const network = this.deploymentAvailableNetworks.find(n => n.id === this.deploymentToken.provider);
        if (network) {
          return network.name;
        }
      }
    },
    doTokenDeployment() {
      this.setProcessingActivate(true);
      if (this.tokenIsReDeployable) {
        this.retryDeploy(this.deploymentToken.id).then(
            () => {
              const lastStep = this.deploymentToken.logMessages[`${Object.keys(this.deploymentToken.logMessages).reduce((a, b) => (Number(a) > Number(b) ? a : b))}`]; // a.k.a get the highest value of the key (the highest step number)
              lastStep.status = 'Processing';
              this.setProcessingActivate(false);
              this.$emit('step-submitted', { success: true });
            },
        ).catch(
            () => {
              this.setProcessingActivate(false);
              this.$emit('step-submitted', { success: false });
            },
        );
      } else {
        this.deployToken({
          deploymentId: this.deploymentToken.id,
          gasPriceInGwei: this.gasPriceInGwei,
        }).then(
            () => {
              this.setProcessingActivate(false);
              this.$emit('step-submitted', { success: true });
            },
        ).catch(
            () => {
              this.setProcessingActivate(false);
              this.$emit('step-submitted', { success: false });
            },
        );
      }
    },
    displayDeployModal() {
      if (!this.tokenIsDeployable && !this.tokenIsReDeployable) {
        this.$emit('step-submitted', { success: false });
        return;
      }
      if (this.isEthereumPublicNetwork()) {
        this.getDeploymentGasCostEstimationInGwei(this.deploymentToken.provider).then(
            (deploymentGasEstimation) => {
              this.gasPriceInGwei = deploymentGasEstimation.gasPriceInGwei;
              this.estimatedDeploymentGas = deploymentGasEstimation.estimatedDeploymentGas;
            },
        ).catch((error) => {
          throw error;
        });
      }
      this.$forceUpdate();
      this.$refs.cpConfirmModal.show();
    },
    getBlockchainIcon() {
      const { provider } = this.deploymentToken;

      const matic = /polygon/;
      const avax = /avalanche/;
      const eth = /ethereum|goerli|rinkeby/;
      const quorum = /quorum/;
      const besu = /besu/;
      const algo = /algorand/;
      const ganache = /ganache/;
      const aptos = /aptos/;
      const solana = /solana/;

      switch (true) {
        case matic.test(provider):
          return PolygonLogo;
        case avax.test(provider):
          return AvalancheLogo;
        case eth.test(provider):
          return EthereumLogo;
        case ganache.test(provider):
          return GanacheLogo;
        case algo.test(provider):
          return AlgorandLogo;
        case quorum.test(provider):
          return QuorumLogo;
        case besu.test(provider):
          return BesuLogo;
        case aptos.test(provider):
          return AptosLogo;
        case solana.test(provider):
          return SolanaLogo;
        default:
          return EthereumLogo;
      }
    },
  },
};
</script>
<style>
.main-text {
  font-size: 16px;
  max-width: 597px;
  text-align: center;
}

.modal-header-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 160%;
  /* identical to box height, or 29px */

  letter-spacing: 0.0015em;

  /* Text/Primary */

  color: #0A1828;
}

.modal-body-text {
  font-size: 16px;
}

.token-description-row {
  width: 315px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 4px 0px;
  gap: 4px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  /* identical to box height, or 28px */

  letter-spacing: 0.15px;
}

.details-note {
  width: 399px;
  text-align: center;
}

.details-intro {
  font-weight: 400;
  font-size: 16px;
  color: #708392;
  padding-bottom: 0.8rem;
}

.token-description-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;

  background: #FFFFFF;
  border-radius: 8px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  /* identical to box height, or 28px */

  letter-spacing: 0.15px;

  width: 100%;
}

.token-description-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  /* identical to box height, or 28px */

  letter-spacing: 0.15px;
  color: #0A1828;

}

.title-column {

  color: #848B93;

  line-height: 175%;

  letter-spacing: 0.15px;

}

header:has(> h5 > .confirm-modal-title) {
  justify-content: center;
}


.confirm-modal-title {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
  /* identical to box height, or 32px */

  text-align: center;
  letter-spacing: 0.15px;

  /* Text/Primary */

  color: #0A1828;
}

#compliance-container {
  background: #0283A8;
  margin-bottom: 2rem;
}

#compliance-container > span {
  color: #FFFCFC;
}

.activate-token footer > .btn-primary {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100%;
  height: 2.6rem;

  /* Primary/Main

Main color used by most components
*/
  background: #0283A8;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.activate-token footer > .btn-default {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 93px;
  height: 42px;

  /* Secondary/Main

Secondary color used by most components
*/
  background: #48525E;
  color: #FFFFFF;
  border-radius: 4px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

#blockchain-container {
  align-items: center;
}

.loading-cover {
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  justify-content: center;
  top: 0;
  left: 10%;
  align-items: center;
}

.loading-spinner {
  align-self: center;
  height: 6rem;
  width: 6rem;
}

.loading-text {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  font-weight: 500;
}

.blockchain-pill {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 4px;

  border: 1px solid #48525E;
  border-radius: 16px;

  gap: 0.25rem;

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: 0.16px;

  /* Secondary/Main */

  color: #48525E;
}

.blockchain-icon {
  height: 20px;
  border: 1px solid #48525E;
  border-radius: 16px;
  padding: 2px;
}

.modal-dialog {
  max-width: 33rem !important;
}

.alert-warning {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  /* or 20px */

  letter-spacing: 0.15px;

  padding: 0rem 1rem;
  margin: 0;
}

.warning-icon-lite {
  filter: brightness(0) saturate(100%);
  filter: invert(75%) sepia(69%) saturate(2325%) hue-rotate(298deg) brightness(98%) contrast(96%);
  /* These two filter attributes transform the entire SVG into white. See this: https://codepen.io/sosuke/pen/Pjoqqp */
  height: 1.2rem;
  width: 1.3rem;
}

.deployer-warning-lite {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: center;
}

.deployer-warning-lite span {
  padding: 0.87rem 0;
}

button.close {
  color: #0A1828;
  font-size: 2rem;
  opacity: 1;
  top: 47%;
}

.modal-body {
  padding: 0 1.5625rem 0 1.5625rem;
}
</style>
