<template>
  <div class="card-container">
    <div
      v-if="loading"
      class="text-center my-2 minimum-height loader"
    >
      <b-row>
        <b-col class="text-center">
          <b-spinner class="align-middle" />
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <b-card class="mb-3">
        <cp-input-container ref="cpInputContainer">
          <cp-confirm-modal
            ref="cpConfirmModal"
            @onCancel="$emit('step-submitted', false)"
            @onOk="submitInfo"
          >
            {{ $t('manageToken.confirm') }}
          </cp-confirm-modal>
          <b-row class="owner-container">
            <b-card
              :title="$t('manageToken.manageRoles.walletMaster')"
              style="width: 25rem"
            >
              <b-card-header class="border-bottom-1 mb-5" />
              <b-row class="mt-3">
                <b-col>
                  <cp-input
                    v-model="ownersModel.masterWalletAddress"
                    :is-readonly="!editMode"
                    :label="$t('manageToken.manageRoles.walletAddress')"
                    name="masterWalletAddress"
                  />
                </b-col>
              </b-row>
              <b-card-footer
                class="border-top-1  mt-5"
                style="display: flex; justify-content: center;"
              />
            </b-card>

            <b-card
              :title="$t('manageToken.manageRoles.walletSyncer')"
              style="width: 25rem"
            >
              <b-card-header class="border-bottom-1 mb-5 padding-left-0">
                <span
                  v-if="!editMode"
                  :class="hsmUsedClass()"
                >
                  {{ usingHSM ? $t('manageToken.manageRoles.HSMWallet') : $t('manageToken.manageRoles.privateWallet') }}
                </span>
              </b-card-header>

              <!-- HSM Alert, visible only when editMode is true and not on Algorand or Aptos networks -->
              <div
                v-show="editMode && !isNonEvmNetwork"
                class="hsm-alert"
              >
                <span>{{ $t('manageToken.manageRoles.HSMinfo_1') }}</span>
                <span>{{ $t('manageToken.manageRoles.HSMinfo_2') }}</span>
              </div>

              <!-- Syncer Address Input -->
              <cp-input
                ref="syncerAddress"
                v-model="ownersModel.registrarOwnerAddress"
                :disabled="hideSyncer"
                :is-readonly="!editMode"
                :label="$t('manageToken.manageRoles.walletAddress')"
                name="syncerOwnerAddress"
                @input="handleRegistrarWalletChange"
              />

              <!-- Private Key Input, shown only when HSM is not used -->
              <cp-input
                v-if="!usingHSM"
                ref="syncerAddressPrivateKey"
                v-model="ownersModel.registrarOwnerPk"
                :disabled="hideSyncer"
                :is-readonly="!editMode"
                :label="$t('manageToken.manageRoles.privateKey')"
                :validate="`${!usingHSM ? 'required' : ''}`"
                class="mt-3"
                input-type="password"
                name="registrarOwnerPk"
              />

              <h5 v-show="!hideSyncer && ownersModel.registrarOwnerAddress">
                {{ $t('manageToken.manageRoles.walletBalance') }}: {{ ownersModel.registrarOwnerBalance }}
              </h5>

              <!-- Switch Footer, visible only when not on Algorand or Aptos networks -->
              <b-card-footer
                v-show="!isNonEvmNetwork"
                class="border-top-1"
                style="display: flex; justify-content: start;"
              >
                <cp-switch
                  v-if="editMode"
                  :disabled="!editMode"
                  :value="hideSyncer"
                  @input="handleHideSyncer"
                />
                <b-card-text
                  v-if="editMode"
                  class="text-muted"
                >
                  <span>{{ $t('manageToken.manageRoles.useHSM') }}</span>
                </b-card-text>
              </b-card-footer>
            </b-card>


            <b-card
              v-if="!isNonEvmNetwork"
              :title="$t('manageToken.manageRoles.omnibusTBE')"
              style="width: 25rem"
            >
              <b-card-header class="border-bottom-1 mb-5" />
              <b-row class="mt-3">
                <b-col>
                  <cp-input
                    v-model="ownersModel.omnibusTBEAddress"
                    :is-readonly="!editMode"
                    :label="$t('manageToken.manageRoles.omnibusTBE')"
                    :validate="validateOmnibusTBEAddress"
                    name="omnibusTBEAddress"
                  />
                </b-col>
              </b-row>
              <b-card-footer
                class="border-top-1  mt-5"
                style="display: flex; justify-content: center;"
              />
            </b-card>

            <b-card
              v-if="!isAlgorandNetwork"
              :title="$t('manageToken.manageRoles.redemptionAddress')"
              style="width: 25rem"
            >
              <b-card-header class="border-bottom-1 mb-5" />
              <b-row class="mt-3">
                <b-col>
                  <cp-input
                    v-model="ownersModel.redemptionAddress"
                    :is-readonly="!editMode"
                    :label="$t('manageToken.manageRoles.redemptionAddress')"
                    :validate="validateRedemptionAddress"
                    name="redemptionAddress"
                  />
                </b-col>
              </b-row>
              <b-card-footer
                class="border-top-1  mt-5"
                style="display: flex; justify-content: center;"
              />
            </b-card>
          </b-row>
        </cp-input-container>
      </b-card>
      <b-card class="mt-2 mb-4 py-4">
        <cp-tabular-headers :columns="rolesTableHeaders" />
        <b-row
          v-for="(role, index) in roles"
          :key="role.roleId"
          class="role-input-row py-2"
        >
          <b-col md="2">
            <cp-select
              v-model="role.role"
              :disabled="(role.ownership === 'hsm') && (isNonEvmNetwork)"
              :options="!isAlgorandNetwork ? rolesOptions: rolesOptionsAlgorand"
              class="value-input"
              input-class="cp-input-override"
              name="role"
            />
          </b-col>
          <b-col md="2">
            <cp-select
              v-model="role.ownership"
              :disabled="(role.ownership === 'hsm') && isAlgorandNetwork"
              :options="ownerShipOptions"
              input-class="cp-input-override"
              name="ownership"
            />
          </b-col>
          <b-col md="4">
            <cp-input
              v-if="role.ownership !== 'multisig'"
              v-model="role.walletAddress"
              :disabled="role.ownership === 'hsm'"
              :label="$t('manageToken.manageRoles.walletAddress')"
              :name="'Wallet Address ' + role.roleId"
              class="value-input"
              hide-label
              input-class="cp-input-override"
            />
            <cp-select
              v-else
              v-model="role.walletAddress"
              :name="'Wallet Address' + role.roleId"
              :options="multisigWalletOptions"
              input-class="cp-input-override"
            />
          </b-col>
          <b-col md="3">
            <cp-input
              v-model="role.comment"
              :disabled="role.ownership === 'hsm'"
              class="value-input"
              input-class="cp-input-override"
              name="comment"
            />
          </b-col>
          <b-col class="delete-button">
            <b-btn
              v-if="roles.length > 1"
              v-b-tooltip.hover="true"
              :disabled="role.ownership === 'hsm'"
              title="Delete"
              variant="default remove-role-button btn-md"
              @click="roles.splice(index, 1)"
            >
              <i class="ion ion-ios-trash" />
            </b-btn>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="d-flex flex-row justify-content-center pt-5 border-top-1">
            <cp-button
              v-if="!isAlgorandNetwork"
              custom-class="add-button"
              size="md"
              @click="addNewRole"
            >
              <i class="mr-1 ion ion-ios-add-circle-outline" />
              {{ $t('manageToken.manageRoles.addRole') }}
            </cp-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import * as _ from 'lodash';
import { isValidAddress } from 'algosdk';
import CpInputContainer from '@/components/common/cpInputContainer';
import { CpInput, CpSelect, CpSwitch } from '@/components/common/standalone-components/inputs';
import CpButton from '@/components/common/standalone-components/cp-button';
import CpConfirmModal from '@/components/common/modals-components/confirm-modal';
import CpTabularHeaders from '../common/tabular-headers';
import CpEditableMixin from '~/mixins/editable-component';
import { i18n } from '@/i18n';

const defaultRole = {
  role: 'issuer',
  walletAddress: '',
  comment: '',
  roleId: 0,
  ownership: 'single',
};

export default {
  name: 'CpManageRolesSettings',
  components: { CpInput, CpInputContainer, CpTabularHeaders, CpButton, CpSelect, CpConfirmModal, CpSwitch },
  mixins: [CpEditableMixin],
  props: {
    submitClicked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      roles: [{
        ...defaultRole,
      }],
      multisigWalletOptions: [],
      nextRoleId: 1,
      swapContractModel: {
        custodianAddress: '',
        stableCoin: '',
        swapContractAddress: '',
      },
      ownersModel: {
        omnibusTBEAddress: '',
        masterWalletAddress: '',
        registrarWalletAddress: '',
        registrarOwnerPk: '',
        registrarOwnerBalance: '',
        redemptionAddress: '',
      },
      rolesTableHeaders: [
        { title: this.$t('manageToken.manageRoles.headers.role'), size: 2 },
        { title: this.$t('manageToken.manageRoles.headers.type'), size: 2 },
        { title: this.$t('manageToken.manageRoles.headers.walletAddress'), size: 4 },
        { title: this.$t('manageToken.manageRoles.headers.comment'), size: 4 },
      ],
      rolesOptions: [
        { value: 'issuer', text: this.$t('manageToken.manageRoles.roles.issuer') },
        { value: 'exchange', text: this.$t('manageToken.manageRoles.roles.exchange') },
        { value: 'transfer_agent', text: this.$t('manageToken.manageRoles.roles.transfer_agent') }],
      rolesOptionsAlgorand: [
        { value: 'issuer', text: this.$t('manageToken.manageRoles.roles.issuer') },
      ],
      ownerShipOptions: [
        { value: 'single', text: this.$t('manageToken.manageRoles.ownership.single') },
        { value: 'multisig', text: this.$t('manageToken.manageRoles.ownership.multisig') },
        { value: 'hsm', text: this.$t('manageToken.manageRoles.ownership.hsm') },
      ],
      hideSyncer: true,
      usingHSM: true,
    };
  },
  computed: {
    ...mapState('configToken', ['deploymentToken', 'deploymentMultiSig']),
    ...mapGetters('configToken', ['getOwners', 'getRoles', 'getMultiSigWallets', 'tokenDeployed', 'getSwapContractModel']),
    isAlgorandNetwork() {
      return this.deploymentToken.provider && this.deploymentToken.provider.includes('algorand');
    },
    isNonEvmNetwork() {
      return this.deploymentToken.provider && (this.deploymentToken.provider.includes('aptos')
        || this.deploymentToken.provider.includes('solana')
        || this.deploymentToken.provider.includes('algorand')
      );
    },
    multiSigWallets() {
      return this.getMultiSigWallets;
    },
    validateOmnibusTBEAddress() {
      if (this.deploymentToken.status === 'success' && !this.ownersModel.omnibusTBEAddress) {
        return '';
      }

      if (this.isAlgorandNetwork) {
        return '';
      }

      return '';
    },
    validateRedemptionAddress() {
      if (this.deploymentToken.status === 'success' && !this.ownersModel.redemptionAddress) {
        return '';
      }

      if (this.isAlgorandNetwork) {
        return '';
      }

      return '';
    },
  },
  watch: {
    submitClicked(value) {
      if (value) {
        if (!this.tokenDeployed) {
          this.submitInfo();
        } else {
          this.displayDeployModal();
        }
      }
    },
    deploymentMultiSig(newMultiSigWallets) {
      if (newMultiSigWallets) {
        const multisigOwners = this.multiSigWallets.filter(item => this.shouldDisplayMultiSigWallet(item));

        if (multisigOwners) {
          this.multisigWalletOptions = multisigOwners.map(owner => ({
            value: owner.walletAddress,
            text: owner.walletName,
          }));
        }
      }
    },
    isAlgorandNetwork() {
      this.updateSyncerAndHSMStates();
    },
    isNonEvmNetwork() {
      this.updateSyncerAndHSMStates();
    },
  },
  created() {
    this.loadPersistedData()
        .then(() => {
          this.loading = false;
        });
    this.editMode = !this.tokenDeployed;
    this.hideSyncer = (!this.tokenDeployed || this.getOwners.registrarOwnerAddress === '') && !this.isAlgorandNetwork;
    const { tokenId } = this.$route.params;
    const issuerId = this.$route.params.idIssuer;
    this.getTokenDeployment({ issuerId, tokenId }); // Update Feature Flag status
    this.updateSyncerAndHSMStates();
  },
  methods: {
    ...mapActions('configToken', ['addPermissions', 'updatePermissions',
      'getWalletRegistrarOwnerBalance', 'getAllMultiSigWallets', 'getTokenDeployment']),
    ...mapMutations('global', ['CALL_ERROR_TOASTER']),
    updateSyncerAndHSMStates() {
      this.hideSyncer = !(this.isNonEvmNetwork);
      this.usingHSM = !(this.isNonEvmNetwork);
    },
    displayDeployModal() {
      this.$refs.cpConfirmModal.show();
    },
    async loadPersistedData() {
      await this.getAllMultiSigWallets({ deploymentId: this.deploymentToken.id });
      const persistedOwners = this.getOwners;
      const persistentSwapContract = this.getSwapContractModel;
      const multisigOwners = this.multiSigWallets.filter(item => this.shouldDisplayMultiSigWallet(item));

      if (persistedOwners) {
        this.ownersModel = { ...persistedOwners };
        if (persistedOwners.registrarOwnerPk || this.editMode) {
          this.usingHSM = false;
        }
      }

      if (persistentSwapContract) {
        this.swapContractModel = { ...persistentSwapContract };
      }

      if (multisigOwners) {
        this.multisigWalletOptions = multisigOwners.map(owner => ({
          value: owner.walletAddress,
          text: owner.walletName,
        }));
      }

      const persistedRoles = this.getRoles;
      if (persistedRoles.length) {
        this.roles = [];
        persistedRoles.forEach((persistedRole) => {
          this.roles.push({
            role: persistedRole.role,
            walletAddress: persistedRole.address,
            comment: persistedRole.comment,
            roleId: this.nextRoleId,
            ownership: persistedRole.ownership,
          });
          this.nextRoleId += 1;
        });
      }

      const order = ['hsm', 'single', 'multisig'];
      this.roles = _.sortBy(this.roles, obj => _.indexOf(order, obj.ownership));
    },
    handleRegistrarWalletChange(walletAddress) {
      if (!this.isAlgorandNetwork || (this.isAlgorandNetwork && isValidAddress(walletAddress))) {
        Promise.resolve(this.getWalletRegistrarOwnerBalance({
          deploymentId: this.deploymentToken.id,
          address: walletAddress,
        }))
            .then(() => {
              this.ownersModel.registrarOwnerAddress = this.deploymentToken.owners ? this.deploymentToken.owners.walletRegistrarOwner || '' : '';
              if (this.isAlgorandNetwork) {
                this.ownersModel.registrarOwnerBalance = this.deploymentToken.owners ? this.deploymentToken.owners.walletRegistrarOwnerBalanceInAlgo || '' : '';
              } else {
                this.ownersModel.registrarOwnerBalance = this.deploymentToken.owners ? this.deploymentToken.owners.walletRegistrarOwnerBalanceInEth || '' : '';
              }
            });
      }
    },
    shouldDisplayMultiSigWallet(item) {
      return (this.deploymentToken.status === 'success' && item.deploymentStatus === 'success' && item.walletAddress)
          || (this.deploymentToken.status !== 'success' && item.deploymentStatus === 'initial' && !item.walletAddress);
    },
    addNewRole() {
      this.roles.push({ ...defaultRole, roleId: this.nextRoleId });
      this.nextRoleId += 1;
    },
    validateRoles() {
      const issuerWalletAddresses = this.roles.map(roleObject => ({
        address: roleObject.walletAddress,
        role: roleObject.role,
        type: roleObject.ownership,
      }));

      if (!issuerWalletAddresses.find(r => r.role === 'issuer')) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.mustHaveIssuer'));
        return false;
      }

      if (this.deploymentToken.version !== 'legacy' && !issuerWalletAddresses.find(r => r.role === 'transfer_agent')) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.mustHaveTransferAgent'));
        return false;
      }

      if (_.uniqBy(issuerWalletAddresses, 'address').length < issuerWalletAddresses.length) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.addressExist'));
        return false;
      }

      if (issuerWalletAddresses.find(a => a.address === this.ownersModel.masterWalletAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.walletAddressMustNotBeMaster'));
        return false;
      }

      if (!this.hideSyncer && issuerWalletAddresses.find(a => a.address === this.ownersModel.walletRegistrarOwner)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.walletAddressMustNotBeWalletSyncer'));
        return false;
      }

      if (issuerWalletAddresses.find(a => a.address === this.ownersModel.registrarOwnerAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.walletAddressMustNotBeWalletSyncer'));
        return false;
      }

      if (issuerWalletAddresses.find(a => this.ownersModel.omnibusTBEAddress && a.address === this.ownersModel.omnibusTBEAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.walletAddressMustNotBeOmnibusTBE'));
        return false;
      }

      if (issuerWalletAddresses.find(a => this.ownersModel.redemptionAddress && a.address === this.ownersModel.redemptionAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.walletAddressMustNotBeRedemptionAddress'));
        return false;
      }

      if (issuerWalletAddresses.find(a => this.swapContractModel.custodianAddress && a.address === this.swapContractModel.custodianAddress)) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianAddressMustNotBeIssuerOrExchangeAddress'));
        return false;
      }

      if (issuerWalletAddresses.find(a => !this.validateRoleAddress(a.type, a.address, 'ETH'))) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.invalidWalletAddress'));
        return false;
      }

      if (this.ownersModel.redemptionAddress && this.ownersModel.omnibusTBEAddress && this.ownersModel.redemptionAddress === this.ownersModel.omnibusTBEAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.omnibusWalletAddressEqualsToRedemption'));
        return false;
      }

      if (this.ownersModel.redemptionAddress && this.ownersModel.redemptionAddress === this.ownersModel.masterWalletAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.masterWalletAddressEqualsToRedemption'));
        return false;
      }

      if (this.ownersModel.omnibusTBEAddress && this.ownersModel.omnibusTBEAddress === this.ownersModel.masterWalletAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.masterWalletAddressEqualsToOmnibusTbe'));
        return false;
      }

      if (this.ownersModel.omnibusTBEAddress && this.ownersModel.omnibusTBEAddress === this.ownersModel.registrarOwnerAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.syncerWalletAddressEqualsToOmnibusTbe'));
        return false;
      }

      if (this.ownersModel.redemptionAddress && this.ownersModel.redemptionAddress === this.ownersModel.registrarOwnerAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.syncerWalletAddressEqualsToRedemption'));
        return false;
      }

      if (this.swapContractModel.custodianAddress && this.swapContractModel.custodianAddress === this.ownersModel.masterWalletAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToMaster'));
        return false;
      }

      if (!this.hideSyncer && this.swapContractModel.custodianAddress && this.swapContractModel.custodianAddress === this.ownersModel.walletRegistrarOwner) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToWalletRegistrar'));
        return false;
      }

      if (this.swapContractModel.custodianAddress && this.swapContractModel.custodianAddress === this.ownersModel.redemptionAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToRedemption'));
        return false;
      }

      if (this.swapContractModel.custodianAddress && this.swapContractModel.custodianAddress === this.ownersModel.omnibusTBEAddress) {
        this.printError(i18n.t('store.toaster.configToken.manageToken.custodianWalletAddressEqualsToOmnibusTbe'));
        return false;
      }
      return true;
    },
    printError(err) {
      this.$log.error('Permissions error:', err);
      this.CALL_ERROR_TOASTER(err);
    },
    validateRoleAddress(type, address) {
      if (this.deploymentToken.status !== 'success' && type === 'multisig') {
        return true;
      }

      if (this.isAlgorandNetwork) {
        return isValidAddress(address);
      }

      return true;
    },
    submitInfo() {
      const deploymentId = this.deploymentToken.id;
      this.$refs.cpInputContainer.validateForm().then((isValid) => {
        const validRoles = this.validateRoles();
        if (!isValid || !deploymentId || !validRoles) {
          this.$emit('step-submitted', { success: false, operation: 'set-roles' });
          return;
        }
        this.editMode = false;
        const addOrUpdatePermissions = !this.tokenDeployed ? 'addPermissions' : 'updatePermissions';
        const roles = this.roles.map(roleObject => ({
          address: roleObject.walletAddress,
          role: roleObject.role,
          comment: roleObject.comment,
          ownership: roleObject.ownership,
        }));

        if (this.ownersModel.redemptionAddress === '') delete this.ownersModel.redemptionAddress;

        this[addOrUpdatePermissions]({
          deploymentId,
          params: { roles, owners: this.ownersModel, hsmUsed: this.hideSyncer },
        }).then(
            () => {
              this.$emit('step-submitted', { success: true, operation: 'set-roles', method: addOrUpdatePermissions });
            },
        ).catch(
            () => {
              this.$emit('step-submitted', { success: false, operation: 'set-roles', method: addOrUpdatePermissions });
            },
        ).finally(
            () => {
              this.editMode = true;
            },
        );
      });
    },
    handleHideSyncer(value) {
      this.hideSyncer = value;
      if (value) {
        this.$refs.syncerAddress.clear();
        this.$refs.syncerAddressPrivateKey.clear();
      }
    },
    hsmUsedClass() {
      return `wallet-type ${this.usingHSM ? 'hsm-wallet' : 'private-wallet'}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-container {
  min-height: 35rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.add-button {
  padding: 14px 85px;
  font-size: 16px;
  font-weight: 500;
  margin: 0 15px;
}

.role-input-row {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 12px;
  padding-right: 12px;

  &:nth-child(even) {
    background-color: #f9f9f9;
  }

  &:nth-child(2) {
    border-top: 1px solid;
    border-color: #e5e5e5;
  }
}

.owner-container {
  display: flex;
  justify-content: space-evenly
}

.delete-button {
  text-align: end;
}

.syncer-background {
  height: 60%;
  width: 100%;
  position: absolute;
  backdrop-filter: blur(3px);
  z-index: 1;
}

.walletSyncer {
  display: flex;
  align-items: center;
  padding-left: 0px;
}

.walletSyncer > span {
  padding-right: 0.8rem;
}

.hsm-alert {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 2px;

  background: #FFE9B1;
  border-radius: 5px;

  color: #000000;

  margin-bottom: 2rem;
}

.card-body > h5 {
  margin-top: 1rem;
}

.card-text > span {
  padding-left: 1rem;
}

.wallet-type {
  width: fit-content;
  left: 0px;
  top: 0px;
  border-radius: 16px;
  padding: 6px 8px;

  color: white;

  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.1599999964237213px;
  text-align: center;

}

.private-wallet {
  background: #04BEC4;
}

.hsm-wallet {
  background: #0283A8;
}

.card-header:has( > .wallet-type) {
  padding-left: 0;
}
</style>
